import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { DateRange } from "@nulogy/components";
import { dateRangeConfig } from "../../playgrounds/date-range";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Date Range`}</h1>
    <p>{`Date ranges allow users to easily enter a range of dates. If the end date is before the start date, by default an error message will be displayed.`}</p>
    <Playground {...dateRangeConfig} mdxType="Playground" />
    <h2>{`Variations`}</h2>
    <h3>{`Date Range with Times`}</h3>
    <Example componentName="date-range" scope={{
      DateRange
    }} example="DateRangeWithTimes" mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={dateRangeConfig.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/date-picker"
        }}>{`DatePicker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/time-picker"
        }}>{`TimePicker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/time-range"
        }}>{`TimeRange`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      